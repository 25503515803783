import * as React from 'react';

type Props = {
  className?: string;
};

const SearchIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    overflow="visible"
    width="64px"
    height="64px"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M27.6171794,9 C33.1015598,9 37.7421848,10.8984348 41.5390544,14.6953044 C45.335924,18.4921848 47.2343588,23.1328098 47.2343588,28.6171794 C47.2343588,32.203125 46.3906088,35.4726576 44.7031088,38.4257772 C47.0761658,40.7988342 51.0312338,44.7539022 54.1953044,47.9179728 C55.8828044,49.8164076 55.8828044,51.609375 54.1953044,53.296875 C53.3515544,54.140625 52.402337,54.5625 51.3476522,54.5625 C50.2929566,54.5625 49.3437392,54.140625 48.5,53.296875 C47.5441865,52.3410615 46.3228702,51.119748 45.0019909,49.798872 L39.3242066,44.1210978 C35.9492066,46.8632826 32.0468642,48.234375 27.6171794,48.234375 C22.132799,48.234375 17.492174,46.3359348 13.6953044,42.5390544 C9.8984348,38.7421848 8,34.1015598 8,28.6171794 C8,23.1328098 9.8984348,18.4921848 13.6953044,14.6953044 C17.492174,10.8984348 22.132799,9 27.6171794,9 Z M27.6509759,14.2171875 C23.5549738,14.2171875 20.0989704,15.6442157 17.2829657,18.4982721 C14.4669741,21.3523285 13.0589783,24.725297 13.0589783,28.6171775 C13.0589783,32.7685275 14.4669741,36.2063699 17.2829657,38.9307049 C20.0989704,41.6550266 23.5549738,43.0171875 27.6509759,43.0171875 C31.4909812,43.0171875 34.8189862,41.6550266 37.6349909,38.9307049 C40.4509825,36.2063699 41.8589783,32.7685275 41.8589783,28.6171775 C41.8589783,24.725297 40.4509825,21.3523285 37.6349909,18.4982721 C34.8189862,15.6442157 31.4909812,14.2171875 27.6509759,14.2171875 Z"
        fill="#1A1919"
      />
    </g>
  </svg>
);

export default SearchIcon;
